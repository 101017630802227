@import url(https://fonts.googleapis.com/css?family=Gentium+Book+Basic|Roboto+Condensed);
/* RGB */
.App-logo {
  font-family: 'Gentium Book Basic', serif;
  font-style: italic;
  font-size: 1.25rem;
  height: 1rem;
  padding: 4px 16px;
  color: #000022;
  display: inline-block; }

.Logo {
  display: inline-block;
  padding: 0.5rem 0; }
  @media screen and (max-width: 750px) {
    .Logo {
      text-align: center;
      width: 100%; } }

.App {
  height: 100vh; }

.App-header {
  background-color: #fbf5f3;
  border-bottom: #c42847 4px solid; }

.Map {
  width: 100%;
  border-bottom: #c42847 4px solid;
  height: 75%;
  min-height: 400px;
  max-height: 100%; }

.nav-buttons {
  list-style: none;
  display: inline-block;
  padding: 0px 4px;
  margin-top: 10px;
  margin-bottom: 10px; }
  @media screen and (max-width: 750px) {
    .nav-buttons {
      margin: 0;
      display: block; } }

.nav-button {
  display: inline-block; }
  @media screen and (max-width: 750px) {
    .nav-button {
      display: block; } }
  .nav-button a {
    text-decoration: none;
    color: #000022;
    line-height: 2rem;
    height: 2rem;
    padding: 0.5rem 0;
    border-bottom: 2px transparent solid;
    margin: 0 0.5rem; }
    @media screen and (max-width: 750px) {
      .nav-button a {
        display: block;
        margin: 0.5rem auto;
        text-align: center; } }
    .nav-button a:hover {
      border-bottom: 2px #e28413 solid; }
    .nav-button a.active {
      border-bottom: 2px #c42847 solid; }
    .nav-button a img {
      vertical-align: middle;
      padding: 0 4px; }

.badge {
  padding: 2px 6px;
  margin-left: 4px;
  font-style: italic;
  font-size: x-small;
  border-radius: 8px;
  background: #99ff80;
  color: #303030; }

.row {
  display: flex;
  padding: 0 16px; }
  @media screen and (max-width: 750px) {
    .row {
      padding: 0;
      width: 100%;
      flex-wrap: wrap; } }

.chart {
  flex-grow: 1; }
  @media screen and (max-width: 750px) {
    .chart {
      display: block;
      width: 100%; } }
  .chart h2 {
    font-size: 2rem;
    border-bottom: 2px #c42847 dashed;
    margin: auto;
    max-width: 150px;
    text-align: center; }

.stats {
  display: flex;
  flex-flow: column;
  vertical-align: top; }
  @media screen and (max-width: 750px) {
    .stats {
      flex-flow: row;
      width: 100%; } }

.stat {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-size: 2rem;
  text-align: center;
  justify-content: center;
  margin: 16px 0; }
  .stat h4 {
    border-bottom: 2px #c42847 dashed;
    margin: 0 auto;
    margin-bottom: 8px; }

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto Condensed', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

