@import url('https://fonts.googleapis.com/css?family=Gentium+Book+Basic|Roboto+Condensed');
@import '_colors.scss';

$mobile: '750px';

.App-logo {
    font-family: 'Gentium Book Basic', serif;
    font-style: italic;
    font-size: 1.25rem;
    height: 1rem;
    padding: 4px 16px;
    color: $text;
    display: inline-block;

}

.Logo {
    display: inline-block;
    padding: 0.5rem 0;

    @media screen and (max-width: $mobile) {
        text-align: center;
        width: 100%;
    }
}

.App {
    height: 100vh;
}

.App-header {
    background-color: $white;
    border-bottom: $primary 4px solid;
}

.Map {
    width: 100%;
    border-bottom: $primary 4px solid;
    height: 75%;
    min-height: 400px;
    max-height:100%;
};

.nav-buttons {
    list-style: none;
    display: inline-block;
    padding: 0px 4px;
    margin-top: 10px;
    margin-bottom: 10px;

    @media screen and (max-width: $mobile) {
        margin: 0;
        display: block;
    }
}

.nav-button {
    display: inline-block;
    
    @media screen and (max-width: $mobile) {
        display: block;
    }

    & a {
        text-decoration: none;
        color: $text;
        line-height: 2rem;
        height: 2rem;
        padding: 0.5rem 0;
        border-bottom: 2px transparent solid;
        margin: 0 0.5rem;

        @media screen and (max-width: $mobile) {
            display: block;
            margin: 0.5rem auto;
            text-align: center;
        }

        &:hover {
            border-bottom: 2px $secondary solid;
        }

        &.active {
            border-bottom: 2px $primary solid;
        }
        & img {
            vertical-align: middle;
            padding: 0 4px;
        }
    }
}

.badge {
    padding: 2px 6px;
    margin-left: 4px;
    font-style: italic;
    font-size: x-small;
    border-radius: 8px;
    background: #99ff80;
    color: #303030;
}

.row {
    display: flex;
    padding: 0 16px;
    @media screen and (max-width: $mobile) {
        padding: 0;
        width: 100%;
        flex-wrap: wrap;
    }
}

.chart {
    flex-grow: 1;

    @media screen and (max-width: $mobile) {
        display: block;
        width: 100%;
    }

    h2 {
        font-size: 2rem;
        border-bottom: 2px $primary dashed;
        margin: auto;
        max-width: 150px;
        text-align: center;
    }
}

.stats {
    display: flex;
    flex-flow: column;
    vertical-align: top;

    @media screen and (max-width: $mobile) {
        flex-flow: row;
        width: 100%;
    }
}

.stat {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    font-size: 2rem;
    text-align: center;
    justify-content: center;
    margin: 16px 0;

    h4 {
        border-bottom: 2px $primary dashed;
        margin: 0 auto;
        margin-bottom: 8px;
    }
}
